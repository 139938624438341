<script>
import History from "../History/History";
import {API} from "@/utils/apiWrapper";

export default {
  name: "ClubViewHistory",
  mixins: [History],
  computed: {
    isClubView() {
      return true
    }
  },
  methods: {
    loadData () {
      const params = {
        queryStringParameters: {
          clubId: this.$route.params.id
        }
      }

      return API.get('TheSpottApi', '/history-customer', params).then(response => {
        this.historyGroups = this.parseResponse(response.data)
      })
    },
  }
}
</script>